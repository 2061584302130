body .notification-block {
  width: 514px;
  box-shadow: 0px 2px 30px 0px #0000000f;
  border-radius: 20px;
  border-color: #0000001a;
}
.noify-block {
  padding: 16px 20px 16px 65px;
  cursor: pointer;
}
.noify-block:focus,
.noify-block:hover {
  background: #e8f0f9;
}
.n-bell {
  left: 12px;
  top: 16px;
}

.f-14 {
  color: #494d55;
}

.notify-box {
  width: 85%;
}
.f-12 {
  color: #0b2443;
}
.notification-wrapper {
  overflow-x: hidden;
  overflow-y: auto;
  height: 435px;
}
