* {
  font-family: "Poppins", sans-serif;
  outline: none !important;
  text-decoration: none !important;
}
html {
  scroll-behavior: smooth;
}
img {
  max-width: 100%;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #0b2443;
  border: 2px solid #0b2443;
}
/* .offcanvas-body ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f5f5f5;
} */
html,
body {
  overflow-x: hidden;
}
input[type="checkbox"],
input[type="radio"],
.form-check-label {
  cursor: pointer;
}
p:last-child {
  margin-bottom: 0 !important;
}
ul,
ol {
  padding: 0 !important;
  margin: 0 !important;
  list-style: none !important;
}
a {
  color: #0b2443;
}
textarea {
  resize: none !important;
}
input:focus {
  box-shadow: none !important;
  outline: none !important;
}
button {
  border: 0;
}
.round-full {
  border-radius: 20px !important;
}
.t-color {
  color: #0b2443 !important;
}
.t-green {
  color: #4cae4c !important;
}
.t-gray {
  color: #929292 !important;
}
.td-gray {
  color: #494d55 !important;
}
.t-pink {
  color: #b982ff !important;
}
.pointer {
  cursor: pointer !important;
}
.h-20 {
  height: 200px;
}
.h-40 {
  height: 400px;
}
.f-10 {
  font-size: 10px;
}
.f-11 {
  font-size: 11px !important;
  line-height: 18px !important;
  font-weight: 300;
}
.f-12 {
  font-size: 12px !important;
  line-height: 18px !important;
}
.f-14 {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  color: #181818;
}
.f-16 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #000000;
}
.f-18 {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}
.f-20 {
  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
  color: #000000;
}
.f-22 {
  font-size: 22px;
  font-weight: 400;
  line-height: 34px;
}
.f-24 {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}
.f-28 {
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  color: #051f61;
}
.fw-300 {
  font-weight: 300 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.f-100 {
  font-size: 100px;
}
.f-48 {
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
}
.f-40 {
  font-size: 38px;
  font-weight: 600;
  line-height: 48px;
}
.f-32 {
  font-size: 32px;
  font-weight: 500;
  line-height: 39px;
}
.f-36 {
  font-size: 36px;
  font-weight: 500;
  line-height: 39px;
}
.bg-default {
  background-color: #00aeef !important;
}
.auth-wrapper {
  background: url(./assets/images/bg-shapes-1.png) repeat-x left bottom;
  background-size: 40%;
  position: relative;
  z-index: 1;
}
/* 12-28-2023 */
body {
  font-family: "Poppins", sans-serif;
  color: #0a2343 !important;
}
.JoinUs {
  padding-top: 10px;
}
.AuthIms {
  width: 100%;
  display: flex;
  align-items: end;
  height: 100%;
  justify-content: center;
  padding: 0px 30px;
  padding-top: 50px;
}
.user-login .AuthIms {
  background: #fff;
  left: 0;
  position: absolute;
  top: 0;
  padding: 0;
}
.user-login .AuthIms img {
  filter: blur(3px);
  object-fit: cover;
}
.user-login .AuthIms,
.user-login .AuthIms img {
  height: 100%;
  width: 100%;
}
a.site-logo img {
  max-width: 200px;
}

/* .AuthIms img {
  height: 100%;
  width: 100%;
  filter: blur(3px);
  object-fit: cover;
} */

.Welcome {
  background: rgba(0, 0, 0, 0.3);
  position: relative;
  max-width: 450px !important;
  text-align: center;
  padding: 20px;
  margin: 0 auto;
}
.Welcome h1 {
  font-weight: 500;
}
.Welcome p {
  font-size: 26px;
  font-weight: 400;
}
form.FormStyle {
  box-shadow: 0 0 10px rgba(187, 187, 187, 0.2);
  padding: 20px;
  border-radius: 20px;
  width: 100%;

  margin: auto 0;
  background-color: #fff;
}
.form-right-figure {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}
@media (min-width: 1100px) {
  form.FormStyle {
    max-width: 500px;
  }
}
.Welcome.ForGot {
  max-width: 550px !important;
}
.otp-input input {
  height: 40px;
  width: 40px !important;
  display: block;
  border: 1px solid #a8a8a8;
  border-radius: 3px;
}
.otp-input > div {
  gap: 13px;
  justify-content: space-between;
}
.margin-auto {
  margin: 0 auto;
}
/* 12-28-2023 Ends */
/* Jan 2 - 2024 */
.VaultId {
  background: #ebebeb;
  padding: 20px;
}
.UserImg {
  height: 100px;
  width: 100px;
  min-width: 100px;
  background: red;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.UserImg img {
  border-radius: 50%;
  height: 100px;
  width: 100px;
  object-fit: cover;
}
.UserImgDetail {
  gap: 50px;
}
.Pipe_C {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.TableDiv table td {
  padding: 5px 50px 5px 10px;
}
.TableDiv table td:first-child {
  font-weight: 600;
}
.TableDiv table {
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 10px;
  display: block;
  padding: 15px 20px;
}
span.PipeId {
  display: block;
  width: 100%;
  color: #00aeef;
  font-weight: 600;
  font-size: 18px;
}
.nopadding {
  padding: 0;
}

/* Jan 2 - 2024 Ends */

/* .auth-wrapper:after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(7px);
  background: rgb(8 8 8 / 32%);
  z-index: -1;
  -webkit-filter: blur(8px);
} */
.auth-left {
  width: 50%;
  /* background: linear-gradient(
    199.99deg,
    rgba(0, 148, 205, 0.91) 29.19%,
    rgba(0, 173, 239, 0) 261.75%
  ); */
}
.auth-right .bg-white.p-5.rounded-4 {
  box-shadow: 0 10px 20px #fff;
}
.auth-right {
  width: 30%;
}
input.form-control {
  background: #fff;
  border: 1px solid #a8a8a8;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 11px 16px;
}
.common-btn {
  background: #0b2443;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  padding: 11px;
  border-radius: 8px;
  color: white;
}
.site-logo {
  top: 50px;
  left: 50px;
  position: absolute;
  z-index: 11;
}
.site-logoo {
  top: 25px;
  left: 25px;
  position: absolute;
  z-index: 11;
}
div#pdf-container .canvas-container {
  max-width: 600px !important;
}
.modal.show .modal-dialog {
  max-width: 700px;
}
.BlockedDomains div {
  width: 100%;
  border: 1px solid #ccc;
  text-align: left;
  padding: 10px;
}
.BlockedDomains {
  gap: 10px;
  margin-bottom: 10px;
}
.BlockedDomains button {
  padding: 10px 20px;
}
.VideoSet {
  position: relative;
  text-align: right;
}
.tooltip-inner ul {
  list-style: decimal !important;
  padding: 0 0 0 10px !important;
}
.about-storage .progress {
  height: 10px;
}
.about-storage .progress-bar {
  background-color: #0b2443;
}
.col-xxl-4.col-sm-6.mb-4.In.Tray {
  order: 0 !important;
}
.In.Tray span,
.In.Tray .folder-footer {
  background: #e8f9ef;
}
.In.Tray span svg {
  color: #00a443;
}
.In.Tray .menu-dot {
  display: none;
}
/* Dashboard Css */
ul.pages-links {
  height: 192px;
  overflow: auto;
}

.logout-btn {
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 0;
}

.layout-sidebar {
  background: #ffffff;
  box-shadow: 0px 2px 100px 0px #0000001a;
  width: 262px;
  height: 100vh;
  padding: 50px 30px;
  overflow: auto;
}

.layout-right {
  overflow: auto;
  height: 100vh;
}
.user-info strong.f-12 {
  margin: 0 0 -4px;
}

.pages-links a:not(:last-child) {
  margin-bottom: 8px;
}
.pages-links a {
  padding: 8px 16px 12px;
  display: block;
  border-radius: 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #0b2443;
}
.pages-links a.active {
  background: #ebf2ff;
  font-weight: 600;
}
.pages-links a svg {
  font-size: 22px;
}

.search-block {
  background-color: #fff;
  box-shadow: 0px 0 30px 10px rgb(0 0 0 / 5%);
  padding: 8px, 16px, 8px, 16px;
  border-radius: 8px;
  width: 336px;
  max-width: 100%;
}
.search-block .search-icon {
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
}

.search-block input {
  /* width: calc(100% - 50px); */
  border: 0;
}

.notify-bell svg {
  font-size: 24px;
}
.notify-count {
  top: 1px;
  right: -8px;
  background: #eb5757;
  border-radius: 50px;
  min-width: 16px;
  min-height: 14px;
  max-width: 24px;
  max-height: 24px;
  display: flex;
  font-size: 8px;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: 2px solid #fff;
}

.recent-data {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  /* background: linear-gradient(0deg, #F2F2F2, #F2F2F2),linear-gradient(0deg, #FFFFFF, #FFFFFF); */
  border: 1px solid #e7f0f9;
  padding: 20px;
  height: 70%;
  border-radius: 20px;
}
th,
td {
  padding: 14px;
}

.recent-data th {
  border-bottom: 1px solid #e7f0f9;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #667085;
}

.file-icon {
  margin-right: 16px;
}
.file-icon img {
  height: 50px;
}

.after-none:after {
  display: none !important;
}

.sort-items {
  min-width: 210px !important;
}
.check-items .form-check-reverse {
  text-align: left;
  margin: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
}
.check-items .form-check-reverse label {
  font-weight: 300;
  cursor: pointer;
  line-height: 14px;
  font-size: 14px;
}
.check-items .form-check-input[type="radio"] {
  background: none;
  border: 0;
}
.check-items .form-check-input:checked[type="radio"] {
  background: url(./assets/images/check.svg) no-repeat center center;
}

.f-14.dropdown-menu a {
  font-size: 14px;
}

.border-btn {
  background-color: transparent !important;
  border: 1px solid #00a1df !important;
  color: #00a1df !important;
  line-height: 20px;
  font-weight: 500;
  font-size: 16px;
  padding: 9px 20px;
  border-radius: 4px;
}

/* label container */
.radio-button-container {
  color: rgba(0, 0, 0, 0.75);
  display: block;
  position: relative;
  padding-left: 30px;
  line-height: 25px;
  margin-bottom: 8px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio-button-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 6px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 50%;
  border: 2px solid #66b7f1;
  transition: all 0.3s;
}

/* On mouse-over, add a grey background color */
.radio-button-container:hover input ~ .checkmark {
  border-color: #66b7f1;
}

/* When the radio button is checked */
.radio-button-container input:checked ~ .checkmark {
  background-color: rgba(0, 0, 0, 0);
  border-color: #66b7f1;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-button-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-button-container .checkmark:after {
  transform: translateY(-50%);
  top: 50%;
  left: 0;
  right: 0;
  margin: auto;
  width: 7.5px;
  height: 7.5px;
  border-radius: 50%;
  background: #66b7f1;
}

.days {
  border: 1px solid #ccc;
  padding: 2px 4px;
}

.access-down {
  margin-top: -5px;
}

/* Edit Profiel Page Css */
.user-id {
  background: #ededeb;
  border-radius: 30px;
  color: #181818;
  padding: 8px;
}

.current-acc {
  background: #b982ff33;
  padding: 8px;
  font-size: 11px;
  font-weight: 500;
  line-height: 11px;
  text-align: center;
  color: #0b0b0b;
}

.current-sub {
  border-radius: 0 0 20px 20px;
  width: calc(100% - 40px);
  background: #b982ff;
  text-align: center;
  padding: 0 0 2px;
  font-size: 12px;
  color: #fff;
  margin: auto;
  right: 0;
  left: 0;
  top: 0;
}

.current-block {
  border: 1px solid #ececec;
  border-top: 2px solid #b982ff !important;
}

.corporate-block {
  border: 1px solid #ececec;
  border-top: 2px solid #4cae4c !important;
}

.corporate-block .current-acc {
  background: #4cae4c33;
}

.privacy-block {
  overflow: auto;
  height: 400px;
}

.privacy-block p {
  margin-bottom: 8px;
}

.privacy-block ul li {
  list-style: disc !important;
  padding: 0 0 0 20px;
  margin-bottom: 8px;
  position: relative;
  display: block;
}

.privacy-block ul li::before {
  content: "";
  width: 6px;
  height: 6px;
  background-color: #000000;
  border-radius: 50px;
  position: absolute;
  display: block;
  left: 6px;
  top: 6px;
}

body .react-tel-input .form-control {
  border: 1px solid #a8a8a8;
  width: 100%;
  padding: 11px 14px 11px 60px;
}

.resend-link {
  width: 246px;
}

.otp-input label + div {
  justify-content: space-between;
}

.otp-input label + div input[type="text"] {
  width: 40px !important;
  height: 40px;
}

.pass-eye span {
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

#test {
  display: none;
}

.user-img {
  width: 100px;
  height: 100px;
}

.user-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.offcanvas-body {
  padding-bottom: 50px !important;
}

@media (min-width: 1599.98px) {
  .col-xxl-4 {
    width: 25% !important;
  }
  ul.pages-links {
    height: calc(100vh - 600px);
  }
}

@media (min-width: 2299.98px) {
  .col-xxl-4 {
    width: 20% !important;
  }
}

@media (min-width: 1024.98px) {
  .table-responsive table {
    width: 100% !important;
  }
  .layout-right {
    width: calc(100% - 262px);
  }
}
@media (max-width: 1599.98px) {
  th,
  td {
    padding: 8px;
    font-size: 12px;
  }
}

@media (min-width: 767.98px) {
  .mxh-100 {
    min-height: 100vh;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  body {
    overflow: auto;
  }
  .auth-right {
    padding: 50px 0;
  }
}

@media (min-width: 320px) and (max-width: 1024px) and (orientation: portrait) {
  ul.pages-links {
    height: calc(100vh - 500px);
  }
}

@media (max-width: 1024.98px) {
  input.form-control {
    font-size: 14px;
    padding: 7px 10px;
  }
  .table-responsive table {
    width: 1000px !important;
  }
  .layout-right {
    height: calc(100vh - 62px);
    width: 100%;
  }
  ul.pages-links {
    height: 250px;
  }

  .f-32 {
    font-size: 18px;
    line-height: 24px;
  }
  .f-48 {
    font-size: 30px;
    line-height: 38px;
  }
  .f-40 {
    font-size: 30px;
    line-height: 38px;
  }
}

@media (max-width: 767.98px) {
  .mxh-100 {
    min-height: auto !important;
  }
  .site-logo {
    top: 30px;
    left: 16px;
  }
  .auth-left,
  .auth-right {
    width: 100%;
    min-height: auto;
  }
  .auth-left .f-32 {
    max-width: 500px;
  }
  .auth-wrapper {
    height: 100vh;
    overflow: auto;
    padding: 100px 0 50px;
  }
  .auth-left {
    padding: 110px 0px 70px;
    margin-top: -100px;
  }
  .auth-right {
    padding: 50px 0;
  }
  .mobile-logo img {
    width: 100px;
  }
  .f-16 {
    font-size: 14px;
    line-height: 22px;
  }
  .f-14 {
    font-size: 12px;
    line-height: 14px;
  }
  body .notification-block {
    width: 320px !important;
  }
  .notify-box {
    width: 100% !important;
  }
  .border-btn {
    font-size: 12px;
    padding: 5px 10px;
  }
  .common-btn {
    font-size: 14px;
    padding: 6px 8px;
  }
  .f-24 {
    line-height: 28px;
    font-size: 20px;
  }
  th,
  td {
    padding: 10px;
    font-size: 12px;
  }
}
.profilesave {
  font-size: 14px;
}
.profile-bg {
  background: #ededeb;
  border-radius: 10px;
}
.border-transparent {
  border: transparent !important;
  width: 70px !important;
}
.radioWidth {
  width: fit-content;
}
.form-check-input {
  border: 2px solid #0b2443 !important;
}
#proxy-renderer {
  min-height: 100vh !important;
}
.ijouxZ {
  height: 100vh !important;
}

/* Landing Page Css */
.home-content,
.poppins,
.home-content .f-16 {
  font-family: "Poppins", sans-serif !important;
}

.t1-color {
  color: #0a2343 !important;
}

.t2-color {
  color: #00aeef !important;
}

/* .home-banner{
  height: calc(100vh - 128px);
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(./assets/images/adminpic.gif) no-repeat right -300px center;
  background-size: 50%;
} */

/* .manu-list li {
  margin-right: 50px;
} */
.manu-list li a {
  color: #0a2343;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  /* line-height: 96px; */
}

.f-80 {
  font-size: 75px;
  font-style: normal;
  font-weight: 600;
  line-height: 90px;
}

.f-25 {
  color: #0a2343;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.custom-btn {
  border-radius: 29px 0px;
  background: #00aeef;
  width: 210px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.516px;
}

.f-49 {
  color: #0a2343;
  font-size: 49px;
  font-style: normal;
  font-weight: 600;
  line-height: 60.5px; /* 123.469% */
}

.home-content .f-24 {
  color: #0a2343;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 25.286px; /* 105.358% */
}

.vailidate-search {
  border-radius: 29px 0 31px 0px;
  border: 2px solid #f2dede;
}

.vailidate-search .custom-btn {
  position: absolute;
  right: 0%;
  height: 100%;
}

.join-community {
  background: url(./assets/images/join.png) no-repeat;
  background-size: cover;
  min-height: auto;
  padding: 12rem 0px;
}

.join-community .custom-btn {
  background: #fff;
  color: #00aeef;
}

.filter {
  filter: brightness(0) invert(1) !important;
}

.page-link li a {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  color: #fff;
  line-height: 24px;
}

.home-footer {
  background: #0a2343;
  padding: 100px 0;
}

.qr-content .f-80 {
  font-size: 70px;
  line-height: 70px;
}

.qr-content .f-49 {
  font-size: 40px;
  line-height: 30px;
}

.vailidate-search input.form-control {
  background: transparent;
}

.bg-none {
  background: transparent !important;
}

.bg-none::after {
  display: none !important;
}

.list-style ul {
  list-style: disc !important;
  padding-left: 16px !important;
}

.list-style ol {
  list-style: decimal !important;
  padding-left: 16px !important;
}
.bgGrey {
  background-color: #808080dc;
}
.commonIcon ul li {
  display: flex !important;
  justify-content: center;
  align-items: center !important;
}
.commonIcon ul li span {
  font-size: 13px;
}
.offcanvas.offcanvas-end.show {
  padding: 20px 25px;
}
.fs-10 {
  font-size: 10px;
}
/* .search-block{
  position: absolute;
  inset: 0px auto auto 0px;
  width: 337px;
  transform: translate3d(-336px, 34px, 0px);
} */

button.btn-close {
  position: absolute !important;
  top: 15px !important;
  right: 15px !important;
}

.SocialIcon {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
}
.SocialIcon a.SignUpButton:first-child {
  max-width: 200px;
}
.SocialIcon a.SignUpButton img {
  max-width: 30px;
}
.SocialIcon a.SignUpButton {
  width: 100%;
  max-width: 70px;
  overflow: hidden;
}

.BannerCContent {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 1200px) {
  .BannerCContent {
    margin-left: -25px;
  }
}
h1.Font-Style {
  font-size: clamp(2rem, 4vw, 5rem);
  font-weight: 600;
}
.HeaderMain {
  padding: 10px 0 0px;
}
.TopBottomSpace01 {
  padding: 0px 0 0;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding-bottom: 70px;
}
.TopBottomSpace02 {
  padding: 40px 0 120px;
}
.TopBottomSpace03 {
  padding: 120px 0 100px;
}
@media (max-width: 767px) {
  .TopBottomSpace02 {
    padding: 60px 0 60px;
  }
  .TopBottomSpace03 {
    padding: 60px 0 60px;
  }
  .SocialIcon a.SignUpButton:first-child {
    max-width: 250px;
  }
}
.VideoSet video#vid {
  height: 100%;
  object-fit: cover;
}
.VideoSet {
  position: absolute;
  right: -500px;
  top: -10px;
  max-width: 1150px;
  width: 100%;
}
.HeaderMenuInner ul {
  gap: 95px;
}
.SignUp button.SignUpButton {
  position: absolute;
  right: -2px;
  height: 78px;
}
.vailidate-search.SignUp02 {
  margin-top: 30px !important;
}
.SignUp {
  position: relative;
}
.SignUp input {
  height: 75px;
  font-size: 18px;
  font-weight: 600;
  padding-left: 40px !important;
  padding-right: 220px;
}
.manu-list li a.custom-btn {
  height: 62px;
  color: #fff;
}
.Font-Style .t2-color {
  white-space: nowrap;
}
.u_vauilt_Img {
  max-width: 750px;
}
.vailidate-search.SignUp {
  max-width: 550px;
  margin-top: 80px;
}
.RoundCirvcle h2 {
  padding: 15px 0 10px;
  font-size: 24px;
  margin: 0;
}

.Step_List ul li .RoundCirvcle {
  background: #fff;
  padding: 0px;
  margin: 60px 0 auto 0;
}

.RoundCirvcle img {
  height: 170px;
}
.RoundCirvcle p {
  max-width: 300px;
  margin: 0 auto;
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
}
.Step_List ul li h2.StepTitle {
  font-size: 36px;
  font-weight: 600;
}
.Step_List ul {
  gap: 70px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(420px, 1fr));
}
.Step_List ul li {
  position: relative;
}
.Arc01 img {
  position: absolute;
  text-align: center;
  margin: -100px 110px 0;
  z-index: -1;
}
li.StepTwo .Arc01 img {
  position: absolute;
  text-align: center;
  margin: 250px 110px 0;
  top: 0;
  z-index: -1;
}
/* .ID_Img img {width: 100%; max-width: 540px !important;} */
h2.HowWork.f-49 {
  font-size: 49px;
}

.SignUp input::placeholder {
  color: #cebbbb;
  opacity: 1;
}
.SignUp input::-ms-input-placeholder {
  color: #cebbbb;
}
.SignUp02 input::placeholder {
  color: #000;
  opacity: 1;
}
.SignUp02 input::-ms-input-placeholder {
  color: #000;
}
.container {
  max-width: 1680px !important;
  width: 90% !important;
}
/* Media  Screen */
@media only screen and (min-width: 1920px) and (max-width: 2580px) {
  .VideoSet {
    max-width: 55vw;
  }
  .HeaderMenuInner ul {
    gap: 135px;
  }
}
@media only screen and (min-width: 1600px) and (max-width: 1920px) {
  .VideoSet {
    max-width: 70vw;
  }
}
@media only screen and (min-width: 1300px) and (max-width: 1580px) {
  .VideoSet {
    max-width: 1020px;
  }

  .f-80 {
    font-size: 65px;
    line-height: 80px;
  }
  .f-25 {
    font-size: 20px;
  }
  /* .BannerCContent { height: 885px; } */
  /* .Step_List ul li .RoundCirvcle {width: 350px; height: 350px;}  */
  .RoundCirvcle h2 {
    padding: 10px 0 15px;
  }
  .Step_List ul {
    gap: 50px;
    grid-template-columns: repeat(3, 1fr);
  }
  .RoundCirvcle img {
    height: 120px;
  }
  .Arc01 img {
    margin: -80px 40px 0;
  }
  li.StepTwo .Arc01 img {
    margin: 170px 40px 0;
  }
  .RoundCirvcle p {
    max-width: 250px;
    font-size: 14px;
    line-height: 24px;
  }
  /* .ID_Img img { max-width: 350px !important;} */
  .join-community {
    min-height: auto !important;
    background-position: left center;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1300px) {
  .f-80 {
    font-size: 65px;
    line-height: 80px;
  }
  .f-25 {
    font-size: 20px;
  }
  .HeaderMenuInner ul {
    gap: 35px;
  }
  /* .Step_List ul li .RoundCirvcle {width: 350px; height: 350px;}   */
  .Step_List ul {
    gap: 50px;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
  .RoundCirvcle p {
    max-width: 220px;
  }
  .Step_List ul li .RoundCirvcle h2 {
    margin: 0;
    padding: 0 0 10px;
  }
  .Arc01 {
    display: none;
  }
  .RoundCirvcle img {
    height: 120px;
  }
  .RoundCirvcle p {
    font-size: 14px;
  }
}
@media only screen and (min-width: 100px) and (max-width: 1024px) {
  .Arc01 {
    display: none;
  }
  .TopBottomSpace01 {
    flex-direction: column;
    padding: 50px 0 20px;
  }
  .VideoSet {
    position: relative;
    right: 0;
    top: 0;
    max-width: 100%;
    margin: 50px 0;
  }
  .HeaderMain {
    padding: 15px 0;
  }
  .manu-list li a.custom-btn {
    height: auto;
  }

  .Step_List ul li .RoundCirvcle {
    margin: 40px auto 70px;
  }
  .RoundCirvcle img {
    height: 135px;
  }
  .RoundCirvcle h2 {
    padding: 10px 0 15px;
  }
  .Step_List ul {
    gap: 0;
  }
  .RoundCirvcle p {
    font-size: 14px;
    max-width: 220px;
  }
}

@media only screen and (min-width: 100px) and (max-width: 1024px) {
  video#vid {
    width: 100%;
    height: 100%;
  }
  h1.f-80,
  .qr-content .f-80 {
    font-size: 40px !important;
    line-height: 50px !important;
  }
  .HeaderMenu ul.manu-list li {
    margin: 0;
  }
  .HeaderMenu {
    flex-direction: column;
  }
  .HeaderMenu .HeaderMenuInner {
    gap: 15px;
  }
  .HeaderMenu .HeaderMenuInner ul {
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px !important;
  }
  .HeaderMenu .HeaderMenuInner ul li:last-child {
    flex: 0 0 100%;
    max-width: fit-content;
  }
  .HeaderMenu .HeaderMenuInner ul li a {
    font-size: 14px;
  }
  h2.f-49 {
    font-size: 32px;
    line-height: 40px;
  }
  .join-community {
    min-height: 100% !important;
    padding: 100px 20px;
  }
  .home-footer {
    padding: 20px 0;
  }
  ul.page-link {
    flex-direction: column;
    gap: 20px;
  }
  .footer-inner {
    text-align: center;
  }
  .Copyright {
    padding: 20px 0;
  }
  ul.social-link {
    justify-content: center;
  }
  .UserImgDetail {
    gap: 20px;
  }

  .Welcome {
    margin-top: 100px !important;
  }
  .HeaderMenu .custom-btn {
    width: auto;
    height: auto;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 10px 0px;
    line-height: normal;
  }
  p.f-25.poppins {
    font-size: 18px;
  }
  .auth-wrapper {
    height: 100% !important;
    padding: 0px 0 50px !important;
  }
  .auth-wrapper.auth-wrapper-inner {
    margin-bottom: 20px;
    padding: 50px 0 0px !important;
  }
  .w-100 {
    margin: 0 !important;
  }
  .bg-white.FormDiv {
    padding: 20px 15px !important;
  }
  h2.mb-2.f-40,
  h2.mb-4.f-40 {
    font-size: 26px;
  }
  form.FormStyle {
    padding: 15px;
  }
  .auth-wrapper-inner + .auth-right {
    padding: 0 0 50px;
  }

  .BannerCContent {
    height: auto;
  }
  .RoundCirvcle img {
    height: 120px;
  }
}
@media (min-width: 1100px) {
  .lamp-box {
    background: url(./assets/images/lamp.png) no-repeat;
  }
}
.auth-wrapper-inner {
  background-position: top center;
}
.FormDiv {
  background-position: 90% 0%;
}
@media only screen and (min-width: 100px) and (max-width: 800px) {
  .Step_List ul {
    gap: 0;
  }
  .Step_List ul {
    flex-direction: column;
  }
  .Step_List ul {
    gap: 50px;
    grid-template-columns: repeat(1, 1fr);
  }
  .qr-content .f-49 {
    font-size: 28px;
  }
  h2.HowWork.f-49 {
    font-size: 29px;
  }
  .vailidate-search.SignUp {
    margin-top: 30px;
  }
  .SignUp button.SignUpButton {
    position: relative;
    right: 0;
  }

  section.StepSection {
    padding: 0px 0;
  }
  .Step_List ul li .RoundCirvcle {
    margin: 40px auto 0px;
  }
  .ID_Img {
    margin-top: 50px;
  }
  /* .ID_Img img {max-width: 340px !important;} */
}

.downloadLinkDiv {
  flex-direction: column;
  max-width: 800px;
  margin: 100px auto;
}
a.downloadLink {
  background: #0b2443;
  padding: 12px 30px;
  color: #fff;
  width: 100%;
  max-width: max-content;
  border-radius: 10px;
  font-size: 26px;
}
.downloadLinkDiv h2 {
  font-size: 64px;
  color: #000;
  font-weight: 800;
}
.downloadLinkDiv h4 {
  color: #474747;
  padding: 10px 0;
}
.downloadLinkDiv p {
  color: #474747;
  max-width: 500px;
  margin-bottom: 30px;
}
.shareModal .modal-content {
  padding: 20px;
}
.downloadLinkfloat {
  right: 10px;
  background: #0b2443;
  padding: 12px 30px;
  color: #fff;
  width: 100%;
  max-width: max-content;
  border-radius: 10px;
  font-size: 26px;
  position: absolute;
  top: 100px;
}

.downloadLinkDiv02 a.downloadLink {
  margin: 10px auto;
  display: block;
  text-align: center;
}
.downloadLinkDiv02 a.downloadLink {
  background: #0b2443;
  padding: 12px 30px;
  color: #fff;
  width: 100%;
  max-width: max-content;
  border-radius: 10px;
  font-size: 26px;
}
.downloadLinkDiv02 h4 {
  line-height: 36px;
}
.passwordeye {
  right: 15px;
  bottom: 9px;
}
.nav-profile-btn {
  border-radius: 50px;
  background: #00aeef;
  width: 210px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.516px;
}
/* contact us page */
.contact-element {
  display: flex;
  width: 100%;
  align-items: center;
  min-height: calc(100vh - 433px);
  padding: 50px 0px;
}

.contact-element textarea {
  height: 200px;
}

.contact-step .contact-number {
  font-size: 40px;
  font-weight: 900;
  background-clip: text;
  -webkit-text-fill-color: rgb(0 0 0 / 0%);
  -webkit-text-stroke: 1px rgb(223 223 223);
  line-height: 50px;
}
.content-area {
  padding-bottom: 190px;
}
.content-area .figure-box {
  text-align: center;
  width: 100%;
  height: 266px;
  bottom: -50px;
}
.profileButton {
  width: 250px;
}
.change-pw {
  max-width: 500px;
}

@media (max-width: 767px) {
  .search-block {
    width: 100%;
  }
  .change-pw {
    max-width: 100%;
  }
  .logo {
    width: 150px;
  }
  .mb-none {
    display: none;
  }
  .mb-w-100 {
    width: 100%;
  }
  .SignUp input {
    padding-right: 100px;
  }
  .vailidate-search .custom-btn {
    font-size: 16px;
    width: 100px;
  }
}

@media (min-width: 767px) {
  .mb-btn.d-flex {
    display: none !important;
  }
}

.verify-signup {
  display: flex;
  min-height: calc(100vh - 175px);
  align-items: center;
  justify-content: center;
}
.verify-signup .common-btn {
  width: 180px !important;
  display: inline-block;
  margin: 0px 10px;
}
.unreadnotifications {
  background: #e8f0f9;
}
.notify-unread {
  background: #eb5757;
  border-radius: 50px;
  min-width: 6px;
  min-height: 6px;
}
.status-time {
  width: 15% !important;
}
.register-dropdown {
  position: absolute;
  z-index: 10;
  background-color: #0a2343;
  width: 210px;
  border-bottom-left-radius: 25px;
  border-top-right-radius: 25px;
}

.register-dropdown button.tab.w-50 {
  border-top-right-radius: 25px;
  background-color: transparent;
  color: #fff;
  padding: 10px;
  border-bottom: 1px solid #fff;
}
.register-dropdown button.tab.active {
  background: #20a7da;
  border-top-right-radius: 25px;
}
.register-dropdown .tab-content {
  padding: 15px;
}
.register-dropdown .tab-content a {
  color: #fff;
  font-size: 16px;
  padding-bottom: 10px;
}
.w-20 {
  width: 20%;
}
.user-info {
  background: #ebf2ff;
  font-weight: 600;
  border-radius: 20px;
}
.user-info img {
  width: 34px;
  height: 34px;
  border-radius: 50%;
}
.opt-box-corporate {
  max-width: 550px;
  margin: 0px auto;
  background: #fff;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
  padding: 25px;
  position: relative;
}
.opt-box-corporate::after {
  content: "";
  position: absolute;
  left: -100px;
  bottom: -115px;
  width: 150px;
  height: 150px;
  z-index: -1;
  background: url(./assets/images/user-otp.png) no-repeat;
}

.verify-corporate-otp .form-right-figure {
  z-index: 9;
}
.verify-corporate-otp {
  background-position: 90% 0%;
  position: relative;
}
.verify-corporate-otp::after {
  position: absolute;
  bottom: 0;
  content: "";
  background: url(./assets/images/Plant.png) no-repeat;
  width: 150px;
  height: 220px;
  left: 35px;
}
.user-info {
  background: #ebf2ff;
  font-weight: 600;
  border-radius: 20px;
}
.user-info img {
  width: 34px;
  height: 34px;
  border-radius: 50%;
}
.domain-cancel {
  border: 1px solid black;
  padding: 6px 9px !important;
}
.auth-password div {
  /* width: 25px !important; */
  justify-content: space-evenly;
}
.auth-password input {
  width: 30px !important;
}
.auth-passwords div {
  /* width: 25px !important; */
  justify-content: space-evenly;
}
.auth-passwords input {
  width: 50px !important;
  height: 50px !important;
  border: 1px solid #a8a8a8;
  border-radius: 5px;
}
.active > .page-link,
.page-link.active {
  background-color: #0a2343 !important;
  border-color: #0a2343 !important;
}
.page-link {
  --bs-pagination-color: #0a2343 !important;
  --bs-pagination-hover-color: #0a2343 !important;
}
.rotate {
  animation: rotateIcon 2s linear infinite;
}

@keyframes rotateIcon {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (min-width: 100px) and (max-width: 800px) {
  .footer-inner div {
    text-align: left;
  }
  .footer-inner p.Copyright {
    text-align: left !important;
  }
}
.password-input {
  position: relative;
}

.password-toggle-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.password-toggle-icon svg {
  font-size: 1.2rem;
}
/* .PasswordChecklist li.valid{
  display: none !important;
}
.PasswordChecklist li{
  display: flex;
  align-items: center !important;
  margin: 2px 0 0 0 !important;
} */
.forgetPwdCorporate li {
  align-items: center !important;
}
figure.form-right-figure img {
  max-width: 200px;
}
form.row.FormStyle.auth-form {
  margin: auto;
}
.fade.authModal.modal .modal-dialog {
  max-width: 800px;
}
.fade.authModal.modal .modal-content {
  padding: 20px;
  overflow: hidden;
}

/*--Auth-Popup--*/

.g_authentication .modal-content {
  height: 524px !important;
  border-radius: 20px;
}
.g_authentication .f-40 img {
  width: 40px;
  margin-left: 20px;
}

.g_authentication .btn-close {
  opacity: 1;
  font-size: 14px;
  top: 30px !important;
  right: 30px !important;
}

.g_authentication .modal-body canvas {
  width: 165px !important;
  height: 165px !important;
}

.g_authentication a img {
  width: 187px;
}

.g_authentication .login_btns a {
  display: inline-block;
}
.common-btn-outline {
  border: 1px solid #0b2443;
  background: white;
  padding: 7px 14px;
  color: #0b2443;
  border-radius: 5px;
}
.completeStep {
  background-color: #051f61;
  color: white;
  border-radius: 50px;
  padding: 7px 14px;
}
.UncompleteStep {
  background-color: gray;
  color: white;
  border-radius: 50px;
  padding: 7px 14px;
}
.completeStepBorder {
  margin: 15px 28px 15px !important;
  border-left: 3px solid rgb(5, 31, 97);
}
.UncompleteStepBorder {
  margin: 15px 28px 15px !important;
  border-left: 3px solid gray;
}