.folder-block {
    border: 1px solid #E7F0F9;
    border-radius: 20px;
}
.folder-footer{
    background-color: #E8F0F9;
    border-radius: 0px 0px 20px 20px;
}
.folder-icon{
    width: 40px;
    height: 40px;
    box-shadow: 0px 2px 100px 0px #0000001A;
    background: #1661BE1A;
    border-radius: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
