.figure-box{
   
    overflow: hidden;
    margin-left: auto;
    margin: 20px 0px;
    width: 90%;
    
}
@media(min-width:1200px){
    .figure-box{
        margin: 35px auto;
    }
.video-outer-box{
    transform: translateX(120px);
}
}

.figure-box img
{
    transition: 0.3s ease-in-out;
    /* width:100%; */
}
 .active figure img{  
    transform: scale(1.4);
}
.video-outer-box .blur-img:nth-child(2) .figure-box{
    opacity: 0.8;
}
.video-outer-box .blur-img:nth-child(3) .figure-box{
    opacity: 0.6;
}
.video-outer-box .blur-img:nth-child(4) .figure-box{
    opacity: 0.3;
}
/* .video-outer-box .active.blur-img .figure-box{
    opacity: 1;
} */